


































































import Vue from "vue";
import { dispatch, ListQuery } from "@/store";
import { download, xlsx } from "@/utils";
import moment from "moment";

export default Vue.extend({
  props: {
    product: String
  },
  data() {
    return {
      dispatch,
      loading: false,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "-update_time",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      fields: [
        {
          title: "批量生成数量",
          dataIndex: "number",
          type: "number",
          min: 1,
          max: 100000,
          precision: 0,
          rules: [
            {
              required: true,
              message: "请输入生成数量"
            }
          ]
        }
      ]
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "联系人",
          dataIndex: "nickname"
        },
        {
          title: "电话",
          dataIndex: "phone"
        },
        {
          title: "邮箱",
          dataIndex: "email",
          width: "200px"
        },
        {
          title: "想要产品",
          dataIndex: "product_name"
        },
        {
          title: "最近想要时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        }
      ];
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .settingsGetWwwRequestList({
          product_code: this.product,
          ...query
        })
        .then(res => {
          this.list = res.results;
          this.query = {
            ...query,
            page_number: res.page_number,
            page_size: res.page_size
          };
          this.count = res.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onExport() {
      this.$message.info("想要信息导出中…");
      this.loading = true;
      let list: any[] = [];
      const getList = (pageNumber: number) =>
        dispatch
          .settingsGetWwwRequestList({
            ...this.query,
            product_code: this.product,
            page_number: pageNumber,
            page_size: 1000
          })
          .then(res => {
            list = [...list, ...res.results];
            if (res.count > list.length) {
              getList(pageNumber + 1);
            } else {
              const data = [
                [
                  "联系人",
                  "电话",
                  "邮箱",
                  "想要产品",
                  "最近想要时间",
                  "创建时间"
                ]
              ];
              list.map(item => {
                data.push([
                  item.nickname,
                  item.phone,
                  item.email,
                  item.product_name,
                  moment(item.update_time).format("YYYY-MM-DD HH:mm:ss"),
                  moment(item.create_time).format("YYYY-MM-DD HH:mm:ss")
                ]);
              });
              const file = xlsx([
                {
                  name: "想要列表",
                  data,
                  cols: [
                    { wch: 15 },
                    { wch: 20 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                  ]
                }
              ]);
              this.$message.success("想要信息导出成功，正在下载…");
              download(file, (list[0]?.product_name || "") + "想要列表.xlsx");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("想要信息导出失败");
          });
      getList(1);
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
